import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-babymassage',
  templateUrl: './babymassage.component.html',
  styleUrls: ['./babymassage.component.scss']
})
export class BabymassageComponent implements OnInit {

  constructor(private title: Title, private meta: Meta,) { }

  ngOnInit(): void {
    this.title.setTitle('Piep Spa - Babymasssage');
    this.meta.updateTag({ name: 'description', content: 'Babymassage te Olen,   Bij een babymassage zorg je voor één op één contact in combinatie met rustgevende bewegingen waar je kind enorm van kan genieten. Ook kan een babymassage vaak helpen tegen bepaalde klachten zoals krampjes, gespannen darmen, ...' });

  }

}
