<div id="banner">
  <div id="banner-title">
    <h1>BABY SPA</h1>
    <h2> Hydrotherapie + babymassage <br/><br/>
      Je geniet van een combinatie van het babybadje en een massage.<br />
      Het zorgt voor een uitgebreid genot en een complete spa ervaring.</h2>
  </div>
  <div
    id="banner-img"
  ></div>
</div>
<div id="info">
  <div id="info-text">
    <h1>HYDROTHERAPIE</h1>
    <div>
      Hydrotherapie is en therapie in chloor vrij water met heel wat positieve effecten.<br/>
      Het is één van de beste manieren om je spruitje helemaal te doen ontspannen.<br/>
      Door de drijvende beweging en gewichtloosheid voelt het voor de baby net of ze terug in de baarmoeder zitten.<br/>
      Met deze unieke vorm word je kind ook nog eens op allerlei manieren gesimuleerd.<br/>
    </div>
    <h1>BABYMASSAGE</h1>
    <div>
      Een massage helemaal afgestemd op je kleine spruitje. <br/>
      Bij een babymassage zorg je voor één op één contact in combinatie met rustgevende bewegingen waar je kind enorm van kan genieten. Ook kan een babymassage vaak helpen tegen bepaalde klachten zoals krampjes, gespannen darmen, ... <br/>
      Een babymassage geeft zowel voor de baby als voor de ouder een enorme voldoening.
    </div>
  </div>
  <div
    id="info-img"
    style="background-image: url('../../assets/images/baby1.jpg');"
  ></div>
  <div id="buttons">
    <div class="tab">
      <button [class.active]="hydrotherapie" (click)="clickHydrotherapie()">Hydrotherapie</button>
      <div [class.active]="hydrotherapie"></div>
    </div>
    <div class="tab">
      <button [class.active]="babymassage" (click)="clickBabymassage()">Babymassage</button>
      <div [class.active]="babymassage"></div>
    </div>

  </div>
  <div [class.active]="hydrotherapie" id="hydrotherapie" class="container">
    <div class="voordelen">
      <h2>VOORDELEN VAN HYDROTHERAPIE</h2>
      <div class="voordeel">
        <h3>Optimale bewegingsvrijheid:</h3>
        <div>Doordat het kindje met zijn kin steunt op een drijfband en verder gewichtloos is kan het volledig zijn eigen gangetje gaan en vrije bewegingen maken.</div>
      </div>
      <div class="voordeel">
        <h3>Versterkt het immuunsysteem:</h3>
        <div>Hydrotherapie stimuleert het hormonale systeem en vergroot de immuniteit.</div>
      </div>
      <div class="voordeel">
        <h3>Geeft een comfortabel gevoel:</h3>
        <div>Door de ontspanning wordt de pijn van bv krampjes, tandjes, … verminderd.</div>
      </div>
      <div class="voordeel">
        <h3>Bevordert het slaapritme:</h3>
        <div>De therapie zorgt voor ontspanning waardoor je baby beter en dieper gaat slapen.</div>
      </div>
      <div class="voordeel">
        <h3>Stimuleert de hersenontwikkeling:</h3>
        <div>Door het lichaamsbewustzijn en coördinatie worden de zintuigen van je baby gestimuleerd.</div>
      </div>
      <div class="voordeel">
        <h3>Verminderd het stressniveau:</h3>
        <div>Door de ontspanning vermindert het huilen en de emotionele stress.</div>
      </div>
      <div class="voordeel">
        <h3>Vergroot de eetlust van de baby:</h3>
        <div>Het warme water in combinatie van zachte bewegingen zorgt ervoor dat de baby meer honger krijgt.</div>
      </div>
      <div class="voordeel">
        <h3>Goed voor het contact tussen ouder en baby:</h3>
        <div>Door kind-ouder time krijgt het kind het gevoel van aandacht en liefde.</div>
      </div>
    </div>
    <div id="info1" class="info">
      <h2>STAAT ER EEN LEEFTIJD OP DE HYDROTHERAPIE?</h2>
      <div>Vanaf +-4 weken kan je langskomen. Verder is de grote van de baby belangrijk.<br/>
        Tot +-70cm kunnen ze perfect genieten van het badje zonder de bodem te raken.<br/>
        Vanaf ze de 70cm Voorbij zijn kunnen ze nog steeds genieten van de brubbels en het water maar beperkt het hun bewegingsvrijheid.<br/>
        Gemiddeld rond 6-7 maanden hebben ze deze lengte bereikt.<br/>
        Maar niet alle baby's zijn het zelfde en groeien even snel.<br/>
        Dus, als je kindje 8 maanden is en hij/zij past nog perfect in onze badjes, dan ben je nog meer als welkom.</div>
    </div>
    <div id="info2" class="info">
      <h2>WANNEER KAN JE BEST LANGSKOMEN VOOR EEN HYDROTHERAPIE SESSIE?</h2>
      <div>Hydrotherapie kan een hele dag door gegeven woorden.<br/>
        Als je overdag lang komt, zorg je er voor dat de baby de dag ontspannen kan starten of verder zetten.<br/>
        Kom je liever ‘s avonds? Dat kan ook, dan kan je baby een ontspannen nachtrust tegemoet gaan.<br/>
          <br/>
        Je kan best je kindje een half uurtje à een uurtje vooraf eten geven zodat dit goed verteerd is, maar de baby toch nog even verzadigd is.<br/>
        Het is perfect mogelijk om ter plaatsen of tijdens de sessie je baby’tje eten te geven.</div>
    </div>
  </div>
  <div [class.active]="babymassage" id="babymassage" class="container">
    <div class="voordelen">
      <h2>VOORDELEN VAN BABYMASSAGE</h2>
      <div class="voordeel">
        <h3>Vermindering van darmkrampjes:</h3>
        <div>Bij een massage wordt hun spijsverteringsorgaan geprikkeld en ontspannen.</div>
      </div>
      <div class="voordeel">
        <h3>Verbeterd de ademhaling:</h3>
        <div>De ademhaling zal door een massage positief beïnvloed, gelijkmatiger en ontspannen worden.</div>
      </div>
      <div class="voordeel">
        <h3>Ontspanning bij onrust:</h3>
        <div>Baby’s geraken vaak gefrustreerd omdat ze bv. meer willen dan ze kunnen, ze voelen zich eenzaam in hun bedje,…<br/>
          Door een babymassage verlaten deze spanningen het lichaam.</div>
      </div>
      <div class="voordeel">
        <h3>Verbeteren van het immuunsysteem:</h3>
        <div>Massage stimuleert een belangrijk verdedigingsmechanisme van het lichaam tegen infecties.</div>
      </div>
      <div class="voordeel">
        <h3>Je baby gaat beter slapen:</h3>
        <div>Door de ontspanning gaat de baby sneller in slaap vallen en slapen ze in het algemeen dieper.</div>
      </div>
      <div class="voordeel">
        <h3>Verminderd het huilen:</h3>
        <div>Huilen kan veel oorzaken hebben maar één ding is zeker, een ontspannen baby die goed in zijn/haar vel zit huilt minder.</div>
      </div>
      <div class="voordeel">
        <h3>Ontwikkeling van de tastzin:</h3>
        <div>De huid is een belangrijk tastorgaan. Hieraan ervaren ze liefde, hitte, kou, pijn,…Voor een baby is dit allemaal nieuw en leren ze door de massage hier aan te wennen.<br/>
          Maar ook door de aanrakingen van de lichaamsdelen leren ze stapje voor stapje dat ze voetjes, handjes, beentjes,… hebben.</div>
      </div>
      <div class="voordeel">
        <h3>Ontspanning:</h3>
        <div>Een massage is voor veel goed maar het zorgt vooral voor een zeer ontspannen baby. En wat is er nu zaliger dan dat?!</div>
      </div>
    </div>
    <div id="info1" class="info">
      <h2>STAAT ER EEN LEEFTIJD OP BABYMASSAGE?</h2>
      <div>Een babymassage kan het best gegeven worden vanaf de geboorte tot ongeveer 9 maanden. Vanaf ongeveer 9 maanden gaan baby’s hun eigen gangetje en is het moeilijk om ze te laten stil liggen tijdens een massage. Des te jonger, des te meer effect.</div>
    </div>
    <div id="info2" class="info">
      <h2>WIE MAG EEN BABYMASSAGE GEVEN?</h2>
      <div>Een babymassage kan op zich door iedereen gegeven worden, maar het effect is dan niet altijd even groot. Een massage heeft het beste effect als de masseur een goede relatie heeft met het kind, meestal is dit mama of papa, hier heeft de baby vaak de beste band mee en is het aangenamer voor het kleintje . Het kind kan zijn lichaam in vertrouwde armen leggen en zich beter ontspannen. De massage kan natuurlijk ook gegeven worden door andere opvoeders. (oma, opa, opvoeder,…)</div>
    </div>
    <div id="info3" class="info">
      <h2>WANNEER WORDT EEN BABYMASSAGE HET BEST GEGEVEN?</h2>
      <div>Het is belangrijk dat je met een gerustgestelde baby aan een massage kan beginnen. Het is best dat de baby een uurtje voor de massage gegeten heeft. Zo is het eten al verteerd maar is er ook voldoende tijd om te masseren voor de volgende maaltijd. Het is ook belangrijk dat je tijd hebt, zodat je baby voelt dat je niet gehaast bent en dus helemaal ontspannen aan je massage kan beginnen.</div>
    </div>
  </div>
</div>
<div class="divider"></div>
<div id="sessies">
  <div class="card">
    <div class="card-title">
      <h1>1 SESSIE</h1>
      <div class="card-prijs"><h2>€55</h2></div>
    </div>
    <div class="card-info">
      <div class="card-text">Een enkele sessie</div>
      <div class="card-ul">
        <div class="card-li"><div>-</div><div>Hydrotherapie</div></div>
        <div class="card-li"><div>-</div><div>Workshop babymassage</div></div>
        <div class="card-li"><div>-</div><div>Nodige materialen (zwempamper, handoeken, ...)</div></div>
        <div class="card-li"><div>-</div><div>Prachtige sfeerbeelden</div></div>
      </div>
    </div>
    <div class="card-button">
      <button routerLink="../boeken">BOEKEN</button>
    </div>
  </div>
  <div class="card">
    <div class="card-title">
      <h1>5 SESSIES</h1>
      <div class="card-prijs"><h2>€250</h2><h3>€50/sessie</h3></div>
    </div>
    <div class="card-info">
      <div class="card-text">Een beurtenkaart van 5 sessies</div>
      <div class="card-ul">
        <div class="card-li"><div>-</div><div>Hydrotherapie</div></div>
        <div class="card-li"><div>-</div><div>Workshop babymassage</div></div>
        <div class="card-li"><div>-</div><div>Nodige materialen (zwempamper, handoeken, ...)</div></div>
        <div class="card-li"><div>-</div><div>Prachtige sfeerbeelden</div></div>
        <div class="card-li" style="color:#089cd8"><div><fa-icon [icon]="['fas', 'gift']"></fa-icon></div><div>Een kei leuk geschenkje!</div></div>
      </div>
    </div>
    <div class="card-button">
      <button routerLink="../boeken">BOEKEN</button>
    </div>
  </div>
  <div class="card">
    <div class="card-title">
      <h1>10 SESSIES</h1>
      <div class="card-prijs"><h2>€450</h2><h3>€45/sessie</h3></div>
    </div>
    <div class="card-info">
      <div class="card-text">Een beurtenkaart van 10 sessies</div>
      <div class="card-ul">
        <div class="card-li"><div>-</div><div>Hydrotherapie</div></div>
        <div class="card-li"><div>-</div><div>Workshop babymassage</div></div>
        <div class="card-li"><div>-</div><div>Nodige materialen (zwempamper, handoeken, ...)</div></div>
        <div class="card-li"><div>-</div><div>Prachtige sfeerbeelden</div></div>
        <div class="card-li" style="color:#089cd8"><div><fa-icon [icon]="['fas', 'gift']"></fa-icon></div><div>Een kei leuk geschenkje!</div></div>
      </div>
    </div>
    <div class="card-button">
      <button routerLink="../boeken">BOEKEN</button>
    </div>
  </div>
  <div class="card">
    <div class="card-title">
      <h1>DUO SESSIE</h1>
      <div class="card-prijs"><h2>€100</h2><h3>€50/kind</h3></div>
    </div>
    <div class="card-info">
      <div class="card-text">Heb je een tweeling of kom je graag samen met een vriend/vriendin? Dat kan!</div>
      <div class="card-ul">
        <div class="card-li"><div>-</div><div>Hydrotherapie</div></div>
        <div class="card-li"><div>-</div><div>Workshop babymassage</div></div>
        <div class="card-li"><div>-</div><div>Nodige materialen (zwempamper, handoeken, ...)</div></div>
        <div class="card-li"><div>-</div><div>Prachtige sfeerbeelden</div></div>
      </div>
    </div>
    <div class="card-button">
      <button routerLink="../boeken">BOEKEN</button>
    </div>
  </div>
</div>
