import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hydrotherapie',
  templateUrl: './hydrotherapie.component.html',
  styleUrls: ['./hydrotherapie.component.scss']
})
export class HydrotherapieComponent implements OnInit {

  constructor(private title: Title, private meta: Meta,) { }

  ngOnInit(): void {
    this.title.setTitle("Piep Spa - Hydrotherapie");
    this.meta.updateTag({ name: 'description', content: "Hydrotherapie is een therapie in chloorvrij water met heel wat positieve effecten. Het is één van de beste manieren om je spruitje helemaal te doen ontspannen." });

  }

}
