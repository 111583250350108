import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-babyspa',
  templateUrl: './babyspa.component.html',
  styleUrls: ['./babyspa.component.scss']
})
export class BabyspaComponent implements OnInit {
  hydrotherapie = true;
  babymassage = false;
  constructor(private title: Title, private meta: Meta,) { }

  ngOnInit(): void {
    this.title.setTitle('Piep Spa - Babyspa');
    this.meta.updateTag({ name: 'description', content: 'Bij deze optie geniet je van een combinatie van het babybadje en een massage. Het zorgt voor een uitgebreider genot en een complete ervaring.' });

  }
  clickHydrotherapie() {
      this.hydrotherapie = true;
      this.babymassage = false;
    }
    clickBabymassage() {
      this.hydrotherapie = false;
      this.babymassage = true;
    }

}
