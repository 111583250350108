<div id="banner" class="bigscreen">
  <div class="banner-foto" id="banner-foto-1">
    <div class="cover">
    <div class="cover-title">ONTSPANNEND</div></div>
  </div>
  <div class="banner-foto" id="banner-foto-2">
    <div class="cover">
    <div class="cover-title">STIMULEREND</div></div>
  </div>
  <div class="banner-foto" id="banner-foto-3">
    <div class="cover">
    <div class="cover-title">BINDEND</div></div>
  </div>
  <div id="banner-cover">
     <div id="banner-title"></div>
    <div id="banner-scroll" (click)="scroll()">
      <div>SCROLL</div>
      <div><fa-icon [icon]="['fas', 'chevron-down']"></fa-icon></div>
    </div>
  </div>
</div>
<div id="banner" class="smallscreen">
  <div class="banner-foto" id="banner-foto-1">
    <div class="cover">
      <div class="cover-text">
        <div class="cover-title">ONTSPANNEND</div>
        <div class="cover-title">STIMULEREND</div>
        <div class="cover-title">BINDEND</div>
      </div>
      <div id="banner-scroll" (click)="scroll()">
        <div>SCROLL</div>
        <div><fa-icon [icon]="['fas', 'chevron-down']"></fa-icon></div>
      </div>
    </div>
  </div>
</div>
<div id="links">
  <div id="inleiding">
    <img src="../../assets/images/piepspalogo.png" />
    <!-- <button>BOEKEN</button> -->
  </div>
  <div id="babyspa" class="card-werken" routerLink="../babyspa">
    <h2>BABY SPA: HYDROTHERAPIE + BABYMASSAGE</h2>
    <h3>Bij deze optie geniet je van een combinatie van het babybadje en een massage.
      Het zorgt voor een uitgebreider genot en een complete ervaring.</h3>
  </div>
  <div id="hydrotherapie" class="card-werken" routerLink="../hydrotherapie">
    <h2>HYDROTHERAPIE</h2>
    <h3>Hydrotherapie is een therapie in chloorvrij water met heel wat positieve effecten.</h3>
  </div>
  <div id="babymassage" class="card-werken" routerLink="../babymassage">
    <h2>BABYMASSAGE</h2>
    <h3>Een massage helemaal afgestemd op je kleine spruitje, met heel wat bevorderende effecten.</h3>
  </div>
</div>
<div id="boeken">

</div>
