import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private title: Title, private meta: Meta,) { }

  ngOnInit(): void {
    this.title.setTitle("Piep Spa");
    this.meta.updateTag({ name: 'description', content: "Piep Spa te Olen. Laad uw baby ontspannen in onze bubbel badjes en via een babymassage. Voor de mama's to be bieden we zwangerschapsmassage aan." });

  }
  goToUrl(url:string) {
    window.open(url, "_blank");
  }
  scroll() {
    document.body.scrollTop = 1000;
    document.documentElement.scrollTop = 1000;
  }
}
