<div id="banner">
  <div id="banner-title">
    <h1>BOEK EEN SESSIE</h1>
    <div id="links">
     <a href="mailto:info@piepspa.be"><h2><fa-icon [icon]="['fas', 'envelope']"></fa-icon> info@piepspa.be</h2></a>
      <a href="tel:+32473825505"><h2><fa-icon [icon]="['fas', 'phone']"></fa-icon> 0473825505</h2></a>
    </div>
    <div id="info-text">
      <div id="text">
        <div class="title">Adres:</div>
        <div class="adres">Sint-Maartenstraat 23 </div>
        <div class="adres">2250 Olen</div>
        <div class="title">Openingsuren:</div>
        <div class="uren"><div class="dag">Maandag:</div><div class="uur"> Gesloten</div></div>
         <div class="uren"><div class="dag">Dinsdag:</div><div class="uur"> 18:00 - 21:00</div></div>
         <div class="uren"><div class="dag">Woensdag: </div><div class="uur">14:00 - 17:30</div></div>
         <div class="uren"><div class="dag">Donderdag:</div><div class="uur"> 18:00 - 21:00</div></div>
         <div class="uren"><div class="dag">Vrijdag:</div><div class="uur"> Gesloten</div></div>
         <div class="uren"><div class="dag">Zaterdag:</div><div class="uur"> 10:00 - 16:00</div></div>
         <div class="uren"><div class="dag">Zondag:</div><div class="uur"> Gesloten</div></div>

    </div>
    <div id="map">
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe width="100%" height="100%"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2503.0728796280305!2d4.854819015861019!3d51.144007145028176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c151e0dee66521%3A0x5fefa71fa9406c83!2sSint-Maartenstraat+23%2C+2250+Olen!5e0!3m2!1snl!2sbe!4v1551552924326"
            frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div
    id="banner-img"
  ></div>
</div>
<div id="info">
<div id="buttons">
  <div class="tab">
    <button [class.active]="online" (click)="clickOnline()"><fa-icon [icon]="['fas', 'calendar-days']"></fa-icon> Online</button>
    <div [class.active]="online" class="bigscreen"></div>
  </div>
  <div class="tab">
    <button [class.active]="mail" (click)="clickMail()"><fa-icon [icon]="['fas', 'envelope']"></fa-icon> Mail ons</button>
    <div [class.active]="mail" class="bigscreen"></div>
  </div>
  <div class="tab">
    <button [class.active]="bel" (click)="clickBel()"><fa-icon [icon]="['fas', 'phone']"></fa-icon> Bel ons</button>
    <div [class.active]="bel" class="bigscreen"></div>
  </div>
  <div class="tab">
    <button [class.active]="bon" (click)="clickBon()"><fa-icon [icon]="['fas', 'gift']"></fa-icon> Cadeaubon</button>
    <div [class.active]="bon" class="bigscreen"></div>
  </div>
</div>
<div [class.active]="online" id="online" class="container">
  <h2>ONLINE</h2>
  <div>Maak online een afspraak via de knop 'Maak afspraak' rechtsonder.<br/>
    U mag altijd onderstaande gegevens in het veld opmerkingen invullen.<br/>
 </div>
    <div class="card-ul">
      <div class="card-li"><div>-</div><div>Naam van het kind</div></div>
      <div class="card-li"><div>-</div><div>Leeftijd van het kind</div></div>
      <div class="card-li"><div>-</div><div>Geslacht van het kind</div></div>
    </div>
    <div><br/>Bij annulatie, gelieve dit 24u op voorhand te laten weten. Anders zijn wij genoodzaakt om de helft van de kosten in rekening te brengen.</div>
</div>
<div [class.active]="mail" id="mail" class="container">
  <h2>MAIL ONS</h2>
  <div>
    Mail ons de volgende gegevens naar <a href="mailto:info@piepspa.be">info@piepspa.be</a> voor een afspaak te maken:
    </div>
    <div class="card-ul">
      <div class="card-li"><div>-</div><div>Naam</div></div>
      <div class="card-li"><div>-</div><div>Naam van het kind</div></div>
      <div class="card-li"><div>-</div><div>Leeftijd van het kind</div></div>
      <div class="card-li"><div>-</div><div>Geslacht van het kind</div></div>
      <div class="card-li"><div>-</div><div>Welke sessie u wil</div></div>
      <div class="card-li"><div>-</div><div>Welke dag(en) u kan</div></div>
      <div class="card-li"><div>-</div><div>Voorkeur tijdstip</div></div>
    </div>
    <div><br/>Bij annulatie, gelieve dit 24u op voorhand te laten weten. Anders zijn wij genoodzaakt om de helft van de kosten in rekening te brengen.</div>
  </div>
<div [class.active]="bel" id="bel" class="container">
  <h2>BEL ONS</h2>
  <div>Bel ons op het nummer <a href="tel:+32473825505">0473825505</a> en dan prikken we meteen samen een datum.</div>
  <div><br/>Bij annulatie, gelieve dit 24u op voorhand te laten weten. Anders zijn wij genoodzaakt om de helft van de kosten in rekening te brengen.</div>
</div>
<div [class.active]="bon" id="bon" class="container">
  <h2>CADEAUBON</h2>
  <div>Piep spa als cadeau? Natuurlijk! Je kan een bon bestellen voor elke sessie. Stuur ons een mailtje <a href="mailto:info@piepspa.be">(info@piepspa.be)</a> of bel ons <a href="tel:+32473825505"></a>(0473825505).</div>
</div>
</div>
