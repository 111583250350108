import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-fotos',
  templateUrl: './fotos.component.html',
  styleUrls: ['./fotos.component.scss']
})
export class FotosComponent implements OnInit {

  constructor(private title: Title, private meta: Meta,) { }

  ngOnInit(): void {
    this.title.setTitle("Piep Spa - Foto's");
    this.meta.updateTag({ name: 'description', content: "Vind hier enkele sfeerfoto's van in onze spa." });

  }

}
