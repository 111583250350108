<nav class="bigscreen">
  <div id="logo" routerLink='home'><img src="../assets/images/piepspalogo.png" /></div>
  <div id="menu">
    <div class="menu-item" routerLink='home'>
      <div>HOME</div>
    </div>
    <div class="menu-item" routerLink='babyspa'>
      <div>BABY SPA</div>
    </div>
    <div class="menu-item" routerLink='hydrotherapie'>
      <div>HYDROTHERAPIE</div>
    </div>
    <div class="menu-item" routerLink='babymassage'>
      <div>BABYMASSAGE</div>
    </div>
    <div class="menu-item" routerLink='overons'>
      <div>OVER ONS</div>
    </div>
    <div class="menu-item" routerLink='boeken'>
      <div>BOEKEN</div>
    </div>
    <div class="menu-item" routerLink='fotos'>
      <div>FOTO'S</div>
    </div>
  </div>
</nav>
<nav class="smallscreen">
  <div id="topbar">
    <div id="logo" routerLink="home">
      <div id="logo"><img src="../assets/images/piepspalogo.png" /></div>
    </div>
    <div id="menu" (click)="menuToggle()"><fa-icon [icon]="['fas', 'bars']"></fa-icon></div>
  </div>
  <div id="menulist" [class.show]="menulist==true" [class.hide]="menulist==false">
    <div class="menu-item" routerLink='home'>
      <div>HOME</div>
    </div>
    <div class="menu-item" routerLink='babyspa'>
      <div>BABY SPA</div>
    </div>
    <div class="menu-item" routerLink='hydrotherapie'>
      <div>HYDROTHERAPIE</div>
    </div>
    <div class="menu-item" routerLink='babymassage'>
      <div>BABYMASSAGE</div>
    </div>
    <div class="menu-item" routerLink='overons'>
      <div>OVER ONS</div>
    </div>
    <div class="menu-item" routerLink='boeken'>
      <div>BOEKEN</div>
    </div>
    <div class="menu-item" routerLink='fotos'>
      <div>FOTO'S</div>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>
<footer>
    <div id="info-text">
      <div id="text">
        <div class="title">Adres:</div>
        <div class="adres">Sint-Maartenstraat 23 </div>
        <div class="adres">2250 Olen</div>
        <div class="title">Openingsuren:</div>
        <div class="uren"><div class="dag">Maandag:</div><div class="uur"> Gesloten</div></div>
         <div class="uren"><div class="dag">Dinsdag:</div><div class="uur"> 18:00 - 21:00</div></div>
         <div class="uren"><div class="dag">Woensdag: </div><div class="uur">14:00 - 17:30</div></div>
         <div class="uren"><div class="dag">Donderdag:</div><div class="uur"> 18:00 - 21:00</div></div>
         <div class="uren"><div class="dag">Vrijdag:</div><div class="uur"> Gesloten</div></div>
         <div class="uren"><div class="dag">Zaterdag:</div><div class="uur"> 10:00 - 16:00</div></div>
         <div class="uren"><div class="dag">Zondag:</div><div class="uur"> Gesloten</div></div>

    </div>
    <div id="map">
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe width="100%" height="100%"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2503.0728796280305!2d4.854819015861019!3d51.144007145028176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c151e0dee66521%3A0x5fefa71fa9406c83!2sSint-Maartenstraat+23%2C+2250+Olen!5e0!3m2!1snl!2sbe!4v1551552924326"
            frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
      </div>
    </div>
    </div>

  <div>
    <a class="f-menu-item" href='mailto:info@piepspa.be'>
      <fa-icon [icon]="['fas', 'envelope']"></fa-icon>info@piepspa.be
    </a>
    <a class="f-menu-item" href='tel:+32473825505'>
      <fa-icon [icon]="['fas', 'phone']"></fa-icon>0473825505
    </a>
    <div class="f-menu-item" (click)="goToUrl('https://www.piepboutique.be/')">
      <fa-icon [icon]="['fas', 'bag-shopping']"></fa-icon>Piep Boutique
    </div>
  </div>
  <div>
    <div class="f-menu-item" (click)="goToUrl('https://www.instagram.com/groep_t.o.m/')">
      <fa-icon [icon]="['fab', 'instagram']"></fa-icon>Instagram
    </div>
    <div class="f-menu-item" (click)="goToUrl('https://www.facebook.com/kleuropmaat1/')">
      <fa-icon [icon]="['fab', 'facebook']" ></fa-icon>Facebook
    </div>
  </div>
  <div class="salonized-booking" data-company="B9X7v2WzkMYXuk4oexv71iUQ" data-color="#089cd8" data-language="nl" data-size="normal" data-position="right"></div>
</footer>
