import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-boeken',
  templateUrl: './boeken.component.html',
  styleUrls: ['./boeken.component.scss']
})
export class BoekenComponent implements OnInit {
  online = true;
  bel = false;
  mail = false;
  bon = false;
  constructor(private title: Title, private meta: Meta,) { }

  ngOnInit(): void {
    this.title.setTitle('Piep Spa - Boeken');
    this.meta.updateTag({ name: 'description', content: 'Alle info voor het boeken van uw sessie. Boek online, via mail info@piepspa.be, of via telefoon 0473825505. Ook cadeaubons zijn beschikbaar.' });

  }
  clickOnline() {
      this.online = true;
      this.bel = false;
      this.mail = false;
      this.bon = false;
    }
    clickBel() {
      this.online = false;
      this.bel = true;
      this.mail = false;
      this.bon = false;
    }
    clickMail() {
      this.online = false;
      this.bel = false;
      this.mail = true;
      this.bon = false;
    }
    clickBon() {
      this.online = false;
      this.bel = false;
      this.mail = false;
      this.bon = true;
    }
}
