<div id="banner">
  <div id="banner-title">
    <h1>OVER ONS</h1>
  </div>
  <div
    id="banner-img"
  ></div>
</div>
<div id="info">
  <div id="info-text1" class="info-text">
    <h1>Anja</h1>
    <div>
    Al van jongs af aan ben ik gepassioneerd bezig met kinderen. Eerst ben ik gestart als kleuterjuf. Die leuke lachjes, grappige woorden, deugnietenstreken, die dingen deden mij elke dag stralen. Na 15 jaar met plezier voor mijn klasje te hebben gestaan wou ik toch net nog iets anders, iets uitdagender, iets waar ik nog meer passie voor had… Een eigen kinderdagverblijf. En voilà, opeens mocht ik trots zeggen dat ik mijn eigen Piepje had.
    </div>
  </div>
  <div
    id="info-img1"
    class="info-img"
    style="background-image: url('../../assets/images/bon.jpg');"
  ></div>
  <div id="info-text2" class="info-text">
    <h1>Nieke</h1>
    <div>
      Na al 8 jaar elke dag op bezoek te gaan bij Piep, besloot ik in mama’s voetsporen te treden en het Piep-team te gaan vergezellen.<br/>
Oh, wat deed ik dit graag! Maar de honger naar nog meer uitdaging was nog niet gestild. Ik wou meer over de kindjes te weten komen, weten hoe ik ze rustig kon maken, weten hoe ouders met krampjes moeten omgaan, weten hoe ik toekomstige mama’s kan klaarstomen en verwennen…<br/>
Terwijl ik elke dag met een glimlach de kindjes in ons Piepje verzorgde ging ik ondertussen ook heel wat cursussen bijvolgen zoals: zwangerschapsmassage, babymassage en gezins- en jongercoach,…<br/>
Allemaal cursussen die ons aan het nadenken zette. Wat zijn onze dromen? hoe kunnen we onze dromen combineren?<br/>
<br/>
Wel… We kunnen met alle trots zeggen dat we onze droom hebben kunnen waarmaken en dat onze Piep een leuke aanvulling heeft gekregen.<br/>
Ziezo, onze eigen Baby spa!
    </div>
  </div>
  <div
    id="info-img2"
    class="info-img"
    style="background-image: url('../../assets/images/boutique2.jpg');"
  ></div>
</div>
