<div id="banner">
  <div id="banner-title">
    <h1>FOTO'S</h1>
  </div>
  <div
    id="banner-img"
  ></div>
</div>
<div class="elfsight-app-6b6ca671-4a5d-482b-a147-cf083bc3fc71 fotos"></div>

