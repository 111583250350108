import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-overons',
  templateUrl: './overons.component.html',
  styleUrls: ['./overons.component.scss']
})
export class OveronsComponent implements OnInit {

  constructor(private title: Title, private meta: Meta,) { }

  ngOnInit(): void {
    this.title.setTitle("Piep Spa - Over ons");
    this.meta.updateTag({ name: 'description', content: "Meer info over de krachten achter onze Babyspa. Kom meer te weten over wie ze zijn." });

  }

}
