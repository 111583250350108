import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faBagShopping, faBars, faCalendarDays, faChevronDown, faEnvelope, faGift, faPhone, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { BabyspaComponent } from './babyspa/babyspa.component';
import { HydrotherapieComponent } from './hydrotherapie/hydrotherapie.component';
import { BabymassageComponent } from './babymassage/babymassage.component';
import { FotosComponent } from './fotos/fotos.component';
import { BoekenComponent } from './boeken/boeken.component';
import { OveronsComponent } from './overons/overons.component';

const appRoutes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'babyspa', component: BabyspaComponent},
  {path: 'hydrotherapie', component: HydrotherapieComponent},
  {path: 'babymassage', component: BabymassageComponent},
  {path: 'boeken', component: BoekenComponent},
  {path: 'overons', component: OveronsComponent},
  {path: 'fotos', component: FotosComponent},
  {path: '**', component: HomeComponent},
  {path: '', component: HomeComponent}
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BabyspaComponent,
    HydrotherapieComponent,
    BabymassageComponent,
    FotosComponent,
    BoekenComponent,
    OveronsComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes, {enableTracing: false, scrollPositionRestoration: 'enabled'}
    ),
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFacebook);
    library.addIcons(faInstagram);
    library.addIcons(faBars);
    library.addIcons(faBagShopping);
    library.addIcons(faEnvelope);
    library.addIcons(faPhone);
    library.addIcons(faChevronDown);
    library.addIcons(faGift);
    library.addIcons(faCalendarDays);
  }

 }
