<div id="banner">
  <div id="banner-title">
    <h1>HYDROTHERAPIE</h1>
    <h2>Hydrotherapie is en therapie in chloor vrij water met heel wat positieve effecten.<br/>
      Het is één van de beste manieren om je spruitje helemaal te doen ontspannen.<br/>
      Door de drijvende beweging en gewichtloosheid voelt het voor de baby net of ze terug in de baarmoeder zitten.<br/>
      Met deze unieke vorm word je kind ook nog eens op allerlei manieren gesimuleerd.<br/></h2>
  </div>
  <div
    id="banner-img"
  ></div>
</div>
<div id="info">
  <div id="info-text">
    <h1>HYDROTHERAPIE</h1>
    <div>
      Hydrotherapie is en therapie in chloor vrij water met heel wat positieve effecten.<br/>
      Het is één van de beste manieren om je spruitje helemaal te doen ontspannen.<br/>
      Door de drijvende beweging en gewichtloosheid voelt het voor de baby net of ze terug in de baarmoeder zitten.<br/>
      Met deze unieke vorm word je kind ook nog eens op allerlei manieren gesimuleerd.<br/>
    </div>
  </div>
  <div
    id="info-img"
    style="background-image: url('../../assets/images/badjes.jpeg')"
  ></div>
  <div id="hydrotherapie" class="container">
    <div class="voordelen">
      <h2>VOORDELEN VAN HYDROTHERAPIE</h2>
      <div class="voordeel">
        <h3>Optimale bewegingsvrijheid:</h3>
        <div>Doordat het kindje met zijn kin steunt op een drijfband en verder gewichtloos is kan het volledig zijn eigen gangetje gaan en vrije bewegingen maken.</div>
      </div>
      <div class="voordeel">
        <h3>Versterkt het immuunsysteem:</h3>
        <div>Hydrotherapie stimuleert het hormonale systeem en vergroot de immuniteit.</div>
      </div>
      <div class="voordeel">
        <h3>Geeft een comfortabel gevoel:</h3>
        <div>Door de ontspanning wordt de pijn van bv krampjes, tandjes, … verminderd.</div>
      </div>
      <div class="voordeel">
        <h3>Bevordert het slaapritme:</h3>
        <div>De therapie zorgt voor ontspanning waardoor je baby beter en dieper gaat slapen.</div>
      </div>
      <div class="voordeel">
        <h3>Stimuleert de hersenontwikkeling:</h3>
        <div>Door het lichaamsbewustzijn en coördinatie worden de zintuigen van je baby gestimuleerd.</div>
      </div>
      <div class="voordeel">
        <h3>Verminderd het stressniveau:</h3>
        <div>Door de ontspanning vermindert het huilen en de emotionele stress.</div>
      </div>
      <div class="voordeel">
        <h3>Vergroot de eetlust van de baby:</h3>
        <div>Het warme water in combinatie van zachte bewegingen zorgt ervoor dat de baby meer honger krijgt.</div>
      </div>
      <div class="voordeel">
        <h3>Goed voor het contact tussen ouder en baby:</h3>
        <div>Door kind-ouder time krijgt het kind het gevoel van aandacht en liefde.</div>
      </div>
    </div>
    <div id="info1" class="info">
      <h2>STAAT ER EEN LEEFTIJD OP DE HYDROTHERAPIE?</h2>
      <div>Vanaf +-4 weken kan je langskomen. Verder is de grote van de baby belangrijk.<br/>
        Tot +-70cm kunnen ze perfect genieten van het badje zonder de bodem te raken.<br/>
        Vanaf ze de 70cm Voorbij zijn kunnen ze nog steeds genieten van de brubbels en het water maar beperkt het hun bewegingsvrijheid.<br/>
        Gemiddeld rond 6-7 maanden hebben ze deze lengte bereikt.<br/>
        Maar niet alle baby's zijn het zelfde en groeien even snel.<br/>
        Dus, als je kindje 8 maanden is en hij/zij past nog perfect in onze badjes, dan ben je nog meer als welkom.</div>
    </div>
    <div id="info2" class="info">
      <h2>WANNEER KAN JE BEST LANGSKOMEN VOOR EEN HYDROTHERAPIE SESSIE?</h2>
      <div>Hydrotherapie kan een hele dag door gegeven woorden.<br/>
        Als je overdag lang komt, zorg je er voor dat de baby de dag ontspannen kan starten of verder zetten.<br/>
        Kom je liever ‘s avonds? Dat kan ook, dan kan je baby een ontspannen nachtrust tegemoet gaan.<br/>
          <br/>
        Je kan best je kindje een half uurtje à een uurtje vooraf eten geven zodat dit goed verteerd is, maar de baby toch nog even verzadigd is.<br/>
        Het is perfect mogelijk om ter plaatsen of tijdens de sessie je baby’tje eten te geven.</div>
    </div>
  </div>
</div>
<div class="divider"></div>
<div id="sessies">
  <div class="card">
    <div class="card-title">
      <h1>1 SESSIE</h1>
      <div class="card-prijs"><h2>€50</h2></div>
    </div>
    <div class="card-info">
      <div class="card-text">Een enkele sessie</div>
      <div class="card-ul">
        <div class="card-li"><div>-</div><div>Hydrotherapie</div></div>
        <div class="card-li"><div>-</div><div>Nodige materialen (zwempamper, handoeken, ...)</div></div>
        <div class="card-li"><div>-</div><div>Prachtige sfeerbeelden</div></div>
      </div>
    </div>
    <div class="card-button">
      <button routerLink="../boeken">BOEKEN</button>
    </div>
  </div>
  <div class="card">
    <div class="card-title">
      <h1>5 SESSIES</h1>
      <div class="card-prijs"><h2>€225</h2><h3>€45/sessie</h3></div>
    </div>
    <div class="card-info">
      <div class="card-text">Een beurtenkaart van 5 sessies</div>
      <div class="card-ul">
        <div class="card-li"><div>-</div><div>Hydrotherapie</div></div>
        <div class="card-li"><div>-</div><div>Nodige materialen (zwempamper, handoeken, ...)</div></div>
        <div class="card-li"><div>-</div><div>Prachtige sfeerbeelden</div></div>
        <div class="card-li" style="color:#089cd8"><div><fa-icon [icon]="['fas', 'gift']"></fa-icon></div><div>Een kei leuk geschenkje!</div></div>
      </div>
    </div>
    <div class="card-button">
      <button routerLink="../boeken">BOEKEN</button>
    </div>
  </div>
  <div class="card">
    <div class="card-title">
      <h1>10 SESSIES</h1>
      <div class="card-prijs"><h2>€400</h2><h3>€40/sessie</h3></div>
    </div>
    <div class="card-info">
      <div class="card-text">Een beurtenkaart van 10 sessies</div>
      <div class="card-ul">
        <div class="card-li"><div>-</div><div>Hydrotherapie</div></div>
        <div class="card-li"><div>-</div><div>Nodige materialen (zwempamper, handoeken, ...)</div></div>
        <div class="card-li"><div>-</div><div>Prachtige sfeerbeelden</div></div>
        <div class="card-li" style="color:#089cd8"><div><fa-icon [icon]="['fas', 'gift']"></fa-icon></div><div>Een kei leuk geschenkje!</div></div>
      </div>
    </div>
    <div class="card-button">
      <button routerLink="../boeken">BOEKEN</button>
    </div>
  </div>
  <div class="card">
    <div class="card-title">
      <h1>DUO SESSIE</h1>
      <div class="card-prijs"><h2>€90</h2><h3>€45/kind</h3></div>
    </div>
    <div class="card-info">
      <div class="card-text">Heb je een tweeling of kom je graag samen met een vriend/vriendin? Dat kan!</div>
      <div class="card-ul">
        <div class="card-li"><div>-</div><div>Hydrotherapie</div></div>
        <div class="card-li"><div>-</div><div>Nodige materialen (zwempamper, handoeken, ...)</div></div>
        <div class="card-li"><div>-</div><div>Prachtige sfeerbeelden</div></div>
      </div>
    </div>
    <div class="card-button">
      <button routerLink="../boeken">BOEKEN</button>
    </div>
  </div>
</div>

