<div id="banner">
  <div id="banner-title">
    <h1>BABYMASSAGE</h1>
    <h2>Een massage helemaal afgestemd op je kleine spruitje. <br/>
      Bij een babymassage zorg je voor één op één contact in combinatie met rustgevende bewegingen waar je kind enorm van kan genieten. Ook kan een babymassage vaak helpen tegen bepaalde klachten zoals krampjes, gespannen darmen, ... <br/>
      Een babymassage geeft zowel voor de baby als voor de ouder een enorme voldoening.</h2>
  </div>
  <div
    id="banner-img"
  ></div>
</div>
<div id="info">
  <div id="info-text">
    <h1>WAAROM EEN BABYMASSAGE?</h1>
    <div>
      Een babymassage is zowel voor de ouders als voor het baby’tje zelf heel aangenaam. Bij een massage ga je heel close en intiem te werk met het kind waardoor de baby een gevoel van liefde, veiligheid en aandacht krijgt. Hierdoor wordt hun gevoel van honger naar liefde gestild.<br/>
Je werkt in op bepaalde plaatsen waar de baby een ontspannen gevoel van krijgt. Veel baby’s hebben last van krampjes of gespannen darmen, door bewegingen op de juiste plaats kan dit de pijn bij het kind verzachten.<br/>
Door op regelmatige basis te masseren wordt het slaapritme bevorderd, verminderd het stress en zorgt het ervoor dat je baby rustiger wordt.<br/>
<br/>
Een babymassage is gewoon heel goed, je stimuleert op deze manier het coördinatievermogen en de motorische ontwikkeling. Het verbeterd de bloedcirculatie in de spieren. Hierdoor worden de spieren sterker en zijn ze in betere staat om bepaalde bewegingen uit te voeren.
    </div>
  </div>
  <div
    id="info-img"
    style="background-image: url('../../assets/images/boutique.jpeg');"
  ></div>
  <div id="babymassage" class="container">
    <div class="voordelen">
      <h2>VOORDELEN VAN BABYMASSAGE</h2>
      <div class="voordeel">
        <h3>Vermindering van darmkrampjes:</h3>
        <div>Bij een massage wordt hun spijsverteringsorgaan geprikkeld en ontspannen.</div>
      </div>
      <div class="voordeel">
        <h3>Verbeterd de ademhaling:</h3>
        <div>De ademhaling zal door een massage positief beïnvloed, gelijkmatiger en ontspannen worden.</div>
      </div>
      <div class="voordeel">
        <h3>Ontspanning bij onrust:</h3>
        <div>Baby’s geraken vaak gefrustreerd omdat ze bv. meer willen dan ze kunnen, ze voelen zich eenzaam in hun bedje,…<br/>
          Door een babymassage verlaten deze spanningen het lichaam.</div>
      </div>
      <div class="voordeel">
        <h3>Verbeteren van het immuunsysteem:</h3>
        <div>Massage stimuleert een belangrijk verdedigingsmechanisme van het lichaam tegen infecties.</div>
      </div>
      <div class="voordeel">
        <h3>Je baby gaat beter slapen:</h3>
        <div>Door de ontspanning gaat de baby sneller in slaap vallen en slapen ze in het algemeen dieper.</div>
      </div>
      <div class="voordeel">
        <h3>Verminderd het huilen:</h3>
        <div>Huilen kan veel oorzaken hebben maar één ding is zeker, een ontspannen baby die goed in zijn/haar vel zit huilt minder.</div>
      </div>
      <div class="voordeel">
        <h3>Ontwikkeling van de tastzin:</h3>
        <div>De huid is een belangrijk tastorgaan. Hieraan ervaren ze liefde, hitte, kou, pijn,…Voor een baby is dit allemaal nieuw en leren ze door de massage hier aan te wennen.<br/>
          Maar ook door de aanrakingen van de lichaamsdelen leren ze stapje voor stapje dat ze voetjes, handjes, beentjes,… hebben.</div>
      </div>
      <div class="voordeel">
        <h3>Ontspanning:</h3>
        <div>Een massage is voor veel goed maar het zorgt vooral voor een zeer ontspannen baby. En wat is er nu zaliger dan dat?!</div>
      </div>
    </div>
    <div id="info1" class="info">
      <h2>WIE MAG EEN BABYMASSAGE GEVEN?</h2>
      <div>Een babymassage kan op zich door iedereen gegeven worden, maar het effect is dan niet altijd even groot. Een massage heeft het beste effect als de masseur een goede relatie heeft met het kind, meestal is dit mama of papa, hier heeft de baby vaak de beste band mee en is het aangenamer voor het kleintje . Het kind kan zijn lichaam in vertrouwde armen leggen en zich beter ontspannen. De massage kan natuurlijk ook gegeven worden door andere opvoeders. (oma, opa, opvoeder,…)</div>
    </div>
    <div id="info2" class="info">
      <h2>STAAT ER EEN LEEFTIJD OP BABYMASSAGE?</h2>
      <div>Een babymassage kan het best gegeven worden vanaf de geboorte tot ongeveer 9 maanden. Vanaf ongeveer 9 maanden gaan baby’s hun eigen gangetje en is het moeilijk om ze te laten stil liggen tijdens een massage. Des te jonger, des te meer effect.</div>
    </div>
    <div id="info3" class="info">
      <h2>WANNEER WORDT EEN BABYMASSAGE HET BEST GEGEVEN?</h2>
      <div>Het is belangrijk dat je met een gerustgestelde baby aan een massage kan beginnen. Het is best dat de baby een uurtje voor de massage gegeten heeft. Zo is het eten al verteerd maar is er ook voldoende tijd om te masseren voor de volgende maaltijd. Het is ook belangrijk dat je tijd hebt, zodat je baby voelt dat je niet gehaast bent en dus helemaal ontspannen aan je massage kan beginnen.</div>
    </div>
  </div>
</div>
<div class="divider"></div>
<div id="sessies">
  <div class="card">
    <div class="card-title">
      <h1>1 SESSIE</h1>
      <div class="card-prijs"><h2>€30</h2></div>
    </div>
    <div class="card-info">
      <div class="card-text">Een enkele sessie</div>
      <div class="card-ul">
        <div class="card-li"><div>-</div><div>Workshop babymassage</div></div>
        <div class="card-li"><div>-</div><div>Nodige materialen (massageolie, handoeken, ...)</div></div>
        <div class="card-li"><div>-</div><div>Prachtige sfeerbeelden</div></div>
      </div>
    </div>
    <div class="card-button">
      <button routerLink="../boeken">BOEKEN</button>
    </div>
  </div>
  <div class="card">
    <div class="card-title">
      <h1>DUO SESSIE</h1>
      <div class="card-prijs"><h2>€50</h2><h3>€25/kind</h3></div>
    </div>
    <div class="card-info">
      <div class="card-text">Heb je een tweeling of kom je graag samen met een vriend/vriendin? Dat kan!</div>
      <div class="card-ul">
        <div class="card-li"><div>-</div><div>Workshop babymassage</div></div>
        <div class="card-li"><div>-</div><div>Nodige materialen (massageolie, handoeken, ...)</div></div>
        <div class="card-li"><div>-</div><div>Prachtige sfeerbeelden</div></div>
      </div>
    </div>
    <div class="card-button">
      <button routerLink="../boeken">BOEKEN</button>
    </div>
  </div>
</div>

